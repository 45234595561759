import { Component, OnInit, Input } from '@angular/core';
import { Artikel, ArtikelStatus, SortFields, SortDirection } from 'src/app/models/model';
import { ModalController } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';
import { ConfigService } from 'src/app/services/config.service';
import { ArtikelService } from 'src/app/services/artikel.service';
import { ZahlenGeneratorService } from '../../services/zahlen-generator.service';

@Component({
  selector: 'app-edit-artikel',
  templateUrl: './edit-artikel.component.html',
  styleUrls: ['./edit-artikel.component.scss'],
})
export class EditArtikelComponent implements OnInit {

  @Input() artikel: Artikel
  isNew: boolean = false

  currentSelectedVerkaeufer: any
  verkaeuferSortBy: SortFields = SortFields.NAME
  verkaeuferSortDirection: SortDirection = SortDirection.DESC

  currentStatus: any
  statusse: ArtikelStatus[] = [ArtikelStatus.NEU, ArtikelStatus.NICHT_VERKAUFT, ArtikelStatus.VERKAUFT, ArtikelStatus.REGISTRIERT]

  compareWith(o1, o2) {
    return o1 === o2;
  }

  handleChange(ev) {
    this.currentStatus = ev.target.value;
  }

  handleSelectedVerkaeuferChange(ev) {
    this.currentSelectedVerkaeufer = ev.target.value;
  }

  constructor(public eventService: EventService, public verkaeuferService: VerkaeuferService, public modalController: ModalController, public config: ConfigService,
  public artikelService: ArtikelService, public zahlenGeneratorService: ZahlenGeneratorService) { }

  ngOnInit() {
    if (!this.artikel) {
      this.isNew = true
      let verkaeuferId: string = this.verkaeuferService.verkaeufers[0].verkaeuferId
      if (this.verkaeuferService.currentVerkaeufer) {
        verkaeuferId = this.verkaeuferService.currentVerkaeufer.verkaeuferId
      }

      this.artikel = {
        name: "",
        qrCode: "",
        preis: 0,
        verkaeuferId: verkaeuferId,
        eventId: this.config.currentEventId,
        status: ArtikelStatus.NEU,
        uploadTimestamp: new Date().getTime(),
        category: ""
      }
    }

    this.currentStatus = this.artikel.status
    this.currentSelectedVerkaeufer = this.verkaeuferService.findByVerkaeuferId(this.artikel.verkaeuferId)
  }

  getErrorMessage(): string {
    let errorMessage = ''

    if (!this.config.isString(this.artikel.name)) {
      errorMessage += 'Bitte benenne den Artikel | '
    }

    if (!this.config.isString(this.artikel.qrCode)) {
      errorMessage += 'Bitte weise dem Artikel einen QR Code zu | '
    }

    if(this.artikelService.findByQrCode(this.artikel.qrCode.toUpperCase()) != undefined && this.isNew) {
      errorMessage += 'Dieser QR Code wurde bereits einem Artikel zugewiesen | '      
    }

    if(!this.zahlenGeneratorService.isCodeValid(this.artikel.qrCode.toUpperCase())) {
      errorMessage += 'Dieser QR Code ist nicht gültig | '      
    }

    if (this.config.isNumber(this.artikel.preis)) {
      this.artikel.preis = +this.artikel.preis
    } else {
      errorMessage += 'Preiseingabe ist keine Zahl'
    }
    
    return errorMessage
  }

  submit() {
    let validatioErrors = this.getErrorMessage()
    if (validatioErrors && validatioErrors.length > 0) {
      this.config.presentToast("Fehler: " + validatioErrors)
      return false;
    }

    this.artikel.status = this.currentStatus
    this.artikel.verkaeuferId = this.currentSelectedVerkaeufer.verkaeuferId

    this.modalController.dismiss({
      'artikel': this.artikel
    });
  }

}
