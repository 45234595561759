import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Artikel, ArtikelStatus, Verkaeufer, SortFields, SortDirection } from 'src/app/models/model';
import { ConfigService } from 'src/app/services/config.service';
import { EditArtikelComponent } from 'src/app/components/edit-artikel/edit-artikel.component';
import { ArtikelService } from 'src/app/services/artikel.service';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';

import { Html5QrcodeScanner } from "html5-qrcode"
import { Html5Qrcode } from "html5-qrcode"
import { Html5QrcodeScanType } from 'html5-qrcode';
import { Router } from '@angular/router';

// To use Html5QrcodeScanner (more info below)
// To use Html5Qrcode (more info below)
//https://github.com/mebjas/html5-qrcode
//https://blog.minhazav.dev/research/html5-qrcode

@Component({
  selector: 'app-artikel',
  templateUrl: './artikel.component.html',
  styleUrls: ['./artikel.component.scss'],
})
export class ArtikelComponent implements OnInit {

  @Input() verkaeuferId;

  // List Filter
  status: ArtikelStatus = undefined

  //Sort Attrs
  sortBy: SortFields = SortFields.CODE
  softDirection: SortDirection = SortDirection.DESC

  constructor(public config: ConfigService, public artikelService: ArtikelService, public verkaeuferService: VerkaeuferService, private _ngZone: NgZone, public router: Router) { }

  ngOnInit() {
  }

  sort(sortBy: SortFields) {
    this._ngZone.run(() => {
      if (this.sortBy == sortBy) {
        if (this.softDirection == SortDirection.DESC) this.softDirection = SortDirection.ASC
        else this.softDirection = SortDirection.DESC

        return
      }
      this.softDirection = SortDirection.DESC
      this.sortBy = sortBy
    })
  }

  search(e) {
    this.artikelService.searchstring = e.target.value
  }

  async openEditView(artikel: Artikel) {
    const { data } = await this.config.presentModal({
      component: EditArtikelComponent, componentProps: {
        'artikel': this.artikelService.copy(artikel)
      }
    })

    if (data && data.artikel) {
      console.debug("Artikel wurde bearbeitet", data)
      this.artikelService.update(data.artikel)
    }
  }

  openVerkaeuferView(verkaeuferId: string) {
    this.router.navigateByUrl("/verkaeufer/" + verkaeuferId)
  }

  delete(artikel: Artikel) {
    //TODO: Artikel auch aus Beleg löschen? Eher nicht, oder....
    this.config.presentAlertConfirm(() => {
      console.debug("Artikel wurde gelöscht")
      this.artikelService.delete(artikel.id)
    }, 'Artikel löschen?', 'Willst du sicher diesen Artikel löschen?')

  }

  searchManuell() {
    this.artikelService.stopScan()
    this.artikelService.searchstring = ""

    let _ref: any = this
    this.config.presentAlertPrompt((data) => {
      if (data && data.input && data.input.length > 0 && data.input.length < 150) {
        let qrCode: string = data.input.trim().toUpperCase()
        _ref.displayArtikelInfos(qrCode)
      }
    }, 'QR Code eingeben', 'Bitte gib den Code ein')
  }

  lastScannedCode: string = ""
  scan() {
    let _ref: any = this
    _ref.artikelService.scanStarted = true

    this.artikelService.html5QrCodeScanner = new Html5Qrcode("artikelReader")

    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      if (_ref.lastScannedCode == decodedText) {
        return
      }
      _ref.lastScannedCode = decodedText
      _ref.displayArtikelInfos(_ref.lastScannedCode)
    };

    const config = { fps: 25, qrbox: { width: 250, height: 250 }, supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA] };

    this.artikelService.html5QrCodeScanner.start(
      { facingMode: "environment" },  // prefer back camera
      config,
      qrCodeSuccessCallback,
      (error) => { }
    );
  }

  displayArtikelInfos(qrCode: string) {
    this.artikelService.searchstring = qrCode
    let artikel: Artikel = this.artikelService.findByQrCode(qrCode)
    if (artikel) {

      let verkaeufer: Verkaeufer = this.verkaeuferService.findByVerkaeuferId(artikel.verkaeuferId)
      if (!verkaeufer) {
        verkaeufer = {
          name: "[Nicht gefunden]",
          verkaeuferId: "-",
          eventId: ""
        }
      }

      let message = `Artikel "${artikel.name}" (${artikel.qrCode}) gehört zu ${verkaeufer.name} (${verkaeufer.verkaeuferId}). Willst du den Artikel jetzt auf Status "Nicht verkauft" setzen?`

      this.config.presentAlertConfirm(() => {
        artikel.status = ArtikelStatus.NICHT_VERKAUFT
        this.artikelService.update(artikel)
      }, 'Artikel gefunden.', message, "Nein", "Ja")

    } else {
      this.config.presentToast("Artikel wurde nicht gefunden")
    }
  }

}

