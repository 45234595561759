import { Pipe, PipeTransform } from '@angular/core';
import { Beleg } from 'src/app/models/model';

@Pipe({
  name: 'belegSort'
})
export class BelegSortPipe implements PipeTransform {

  transform(belege: Beleg[], searchstring: string): Beleg[] {
    if (!belege) return []
    return belege.filter(beleg =>
      (
        beleg.gesamtpreis > 0 &&      // => hide temp beleg
        (searchstring == null || searchstring.trim().length == 0 || this.foundBySearchstring(beleg, searchstring))
      )
    ).sort((a: Beleg, b: Beleg) => {
      return b.timestamp - a.timestamp
    });;
  }

  foundBySearchstring(beleg: Beleg, searchstring: string): boolean {
    let artikelIds: string = ""
    let artikelNamen: string = ""

    if (beleg.artikels && beleg.artikels.length > 0) {
      beleg.artikels.forEach(artikel => {
        artikelIds += artikel.qrCode + " "
        artikelNamen += artikel.name + " "
      });
    }

    return this.search(beleg.belegnummer, searchstring) ||
      this.search(beleg.zahlungsart, searchstring) ||
      this.search(artikelIds, searchstring) ||
      this.search(artikelNamen, searchstring)
  }

  private search(s: string, searchstring: string): boolean {
    return s != undefined && s.toLowerCase().trim().indexOf(searchstring.toLowerCase().trim()) != -1
  }
}
