import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';
import { Verkaeufer, Zahlungsart, ArtikelStatus } from 'src/app/models/model';
import { ExportService } from 'src/app/services/export.service';
import { ArtikelService } from 'src/app/services/artikel.service';
import { BelegService } from 'src/app/services/beleg.service';
import { TEMP_BELEG_ID } from 'src/app/models/constants';
import { EventService } from 'src/app/services/event.service';
import { Chart, registerables } from 'chart.js';

@Injectable({
  providedIn: 'root'
})
export class AuswertungenService {

  //Prozentual am mesiten Verkauft (je in geld und anzahl)
  //Gesamt: Am meisten Verkauuft (je in geld und anzahl)
  //Am meisten mitgebracht (je in geld und anzahl)

  totalCount = []
  totalInEuro = []
  totalSoldCount = []
  totalSoldInEuro = []
  percantageSoldCount = []
  percantageSoldInEuro = []


  constructor(public config: ConfigService, public verkaeuferService: VerkaeuferService, private exportService: ExportService, private belegService: BelegService,
    private artikelService: ArtikelService, public eventService: EventService) { }


  calculateSortedVerkaeuferStats(numberOfReturns: number = 9999) {
    this.totalCount = []
    this.totalInEuro = []
    this.totalSoldCount = []
    this.totalSoldInEuro = []
    this.percantageSoldCount = []
    this.percantageSoldInEuro = []

    let verkaeuferDict: any = this.calcVerkaeuferStats()

    // Create items array
    var items = Object.keys(verkaeuferDict).map(function (key) {
      return verkaeuferDict[key];
    });

    this.totalCount = [...items].sort((a: any, b: any) => {
      return b.totalCount - a.totalCount
    }).slice(0, numberOfReturns);

    this.totalInEuro = [...items].sort((a: any, b: any) => {
      return b.totalInEuro - a.totalInEuro
    }).slice(0, numberOfReturns);

    this.totalSoldCount = [...items].sort((a: any, b: any) => {
      return b.totalSoldCount - a.totalSoldCount
    }).slice(0, numberOfReturns);

    this.totalSoldInEuro = [...items].sort((a: any, b: any) => {
      return b.totalSoldInEuro - a.totalSoldInEuro
    });

    this.percantageSoldCount = [...items].sort((a: any, b: any) => {
      return b.percantageSoldCount - a.percantageSoldCount
    }).slice(0, numberOfReturns);

    this.percantageSoldInEuro = [...items].sort((a: any, b: any) => {
      return b.percantageSoldInEuro - a.percantageSoldInEuro
    }).slice(0, numberOfReturns);
  }

  private calcVerkaeuferStats(): any {
    let verkaeuferDict: any = {}

    for (let _verkaeufer of this.verkaeuferService.verkaeufers) {
      let totalCount: number = 0
      let totalInEuro: number = 0
      let totalSoldCount: number = 0
      let totalSoldInEuro: number = 0

      for (let _artikel of this.artikelService.findByVerkaeuferId(_verkaeufer.verkaeuferId)) {
        totalCount++
        totalInEuro += +_artikel.preis

        if (_artikel.status == ArtikelStatus.VERKAUFT) {
          totalSoldCount++
          totalSoldInEuro += +_artikel.preis
        }
      }

      let percantageSoldCount: number = 100 / totalCount * totalSoldCount
      if (totalCount == 0) percantageSoldCount = 0

      let percantageSoldInEuro: number = 100 / totalInEuro * totalSoldInEuro
      if (totalInEuro == 0) percantageSoldInEuro = 0

      verkaeuferDict[_verkaeufer.verkaeuferId] = {
        verkaeuferId: _verkaeufer.verkaeuferId,
        totalCount: totalCount,
        totalInEuro: totalInEuro,
        totalSoldCount: totalSoldCount,
        totalSoldInEuro: totalSoldInEuro,
        percantageSoldCount: percantageSoldCount,
        percantageSoldInEuro: percantageSoldInEuro
      }
    }

    return verkaeuferDict
  }



  // *** Daten für grafische Bereitstellung ***

  chartTotalSoldCountPerTime: Chart
  chartTotalCountPerCustomer: Chart
  // chartLabels60Minutes: string[] = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
  chartLabels60Minutes: string[] = ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00']

  public chartTotalSoldCountPer30Minutes(): number[] {
    let value: number[] = new Array(48).fill(0);
    for (let beleg of this.belegService.belege) {
      if (beleg.id.indexOf(TEMP_BELEG_ID) != -1) continue
      let date: Date = new Date(beleg.timestamp);
      let hour: number = date.getHours()
      let minute: number = date.getMinutes();
      let minuteIndex: number = minute < 30 ? 0 : 1;
      let index: number = hour * 2 + minuteIndex

      value[index] += (beleg.artikels.length + beleg.einmalArtikels.length)
    }

    return value
  }

  public chartTotalSoldCountPer60Minutes(): number[] {
    let _value: number[] = this.chartTotalSoldCountPer30Minutes()
    let value = []

    for (let i = 8; i < 18; i++) {
      value[i - 8] = _value[i * 2] + _value[i * 2 + 1]
    }

    return value
  }


}
