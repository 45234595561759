import { Injectable } from '@angular/core';
import { VerkausEvent, Kasse, Beleg, EinmalArtikel } from '../models/model';
import { ArtikelService } from './artikel.service';
import { DataService } from './data.service';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';
import { BelegService } from 'src/app/services/beleg.service';
import { Subscription } from 'rxjs';
import { TEMP_BELEG_ID } from 'src/app/models/constants';

@Injectable({
  providedIn: 'root'
})
export class EventService {
 
  currentSubscription: Subscription
  currentEvent: VerkausEvent

  constructor(private dataService: DataService, private artikelService: ArtikelService, private verkaeuferService: VerkaeuferService, private belegService: BelegService) {

  }

  selectEvent(eventId: string) {
    this.dataService.setReferences(eventId)
    this.eventChanged(eventId)
    this.verkaeuferService.eventChanged(eventId)
    this.artikelService.eventChanged(eventId)
    this.belegService.eventChanged(eventId)
  }

  update(obj: VerkausEvent) {
    this.dataService.updateEvent(obj)
    console.debug("Event aktualisiert", obj)
  }

  findKasseByNamen(kassenname: string): Kasse | undefined {
    return this.currentEvent.kassen.find((obj) => {
      return obj.name == kassenname;
    });
  }

  findCurrentBelegIdByKassenname(kassenname: string): string | undefined {
    let kasse: Kasse | undefined = this.findKasseByNamen(kassenname)
    if (kasse) {
      return kasse.currentBelegId
    }
    return undefined
  }
  //TODO wenns currentbelegid in kasse nciht mehr gibt, dann auf temporary stellen
  updateKasse(neueKasse: Kasse) {
    for (let kasseFromEvent of this.currentEvent.kassen) {
      if (neueKasse.name == kasseFromEvent.name) {
        kasseFromEvent = neueKasse
        let tmpBeleg: Beleg = this.belegService.findById(neueKasse.currentBelegId)
        if (tmpBeleg) {
          this.belegService.currentBeleg = tmpBeleg
        }
        this.update(this.currentEvent)
        return
      }
    }

  }

  eventChanged(eventId: string) {
    if (this.currentSubscription) this.currentSubscription.unsubscribe();

    this.currentSubscription = this.dataService.event(eventId).subscribe((event: VerkausEvent) => {
      console.log("Aktualisiertes Event vom Server", event)
      //TODO: Bei mehreren Events wird es immer ueberschrieben.... if currenteventid muss her...

      if(!event) return
      
      this.currentEvent = event

      if (!this.belegService.selectedKasse) {
        this.belegService.selectedKasse = event.kassen[0]
      } else {
        //evtl. hat sich die Kasse vom Event aktualisiert, dann müssen wir diese nun auch wieder zuordnern
        this.belegService.selectedKasse = this.findKasseByNamen(this.belegService.selectedKasse.name) //TODO: iif not found
      }

      if (!this.belegService.belege) return
      let tmpBeleg: Beleg = this.belegService.findById(this.belegService.selectedKasse.currentBelegId)
      if (tmpBeleg) {
        this.belegService.currentBeleg = tmpBeleg
      }

    })
  }

  addEvent() {
    let kasse_1: Kasse = {
      name: "Kasse 1",
      currentBelegId: TEMP_BELEG_ID + "kasse_1",
      kassenId: "kasse_1",
      suffix: "_1"
    }

    let kasse_2: Kasse = {
      name: "Kasse 2",
      currentBelegId: TEMP_BELEG_ID + "kasse_2",
      kassenId: "kasse_2",
      suffix: "_2"
    }

    let tasche: EinmalArtikel = {
      name: "Tasche",
      preis: 2
    }

    var event: VerkausEvent = {
      name: "Zweite Liebe",
      lastUsedEtikettenCode: "1111",
      lastUsedBelegCode: "1111",
      lastVerkaeuferId: "1111",
      timestamp_von: 1742040000000,
      timestamp_bis: 1742054400000,
      locationName: "Bürgerhaus Endingen",
      city: "Endingen",
      locationAdresse: "St. Jakobsgäßli 4, 79346 Endingen",
      organisatorId: "N/A",
      kassen: [kasse_1, kasse_2],
      einmalArtikels: [tasche]
    }

    let eventId: string = this.dataService.createEvent(event)
    console.log("eventId", eventId)
  }




}
