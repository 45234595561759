import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from "@angular/core";
import { AuswertungenPage } from 'src/app/pages/auswertungen/auswertungen.page';
import { AuswertungenService } from 'src/app/services/auswertungen.service';

@Injectable()
export class AuswertungsGuard implements CanActivate  {

    constructor(private auswertungenService: AuswertungenService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.auswertungenService.calculateSortedVerkaeuferStats()

        return true
      }

}
