import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Artikel, VerkausEvent, Verkaeufer, Beleg, Database, EinmalArtikel, Kasse, ArtikelStatus } from '../models/model';
import { ConfigService } from 'src/app/services/config.service';
import { ZahlenGeneratorService } from 'src/app/services/zahlen-generator.service';
import { HttpClient } from '@angular/common/http';
import { TEMP_BELEG_ID } from '../models/constants';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService implements Database {

  _event: VerkausEvent
  _artikels: Artikel[] = []
  _belege: Beleg[] = []
  _verkaeufers: Verkaeufer[] = []

  emitterEvent
  observablEvent: Observable<VerkausEvent>

  emitterArtikel
  observableArtikel: Observable<Artikel[]>

  emitterVerkaeufer
  observableVerkaeufer: Observable<Verkaeufer[]>

  emitterBelege
  observableBelege: Observable<Beleg[]>

  constructor(private config: ConfigService, private http: HttpClient, private zahlenGenerator: ZahlenGeneratorService) {
    // this.observableVerkaeufer = of(this._verkaeufers)
    this.observablEvent = Observable.create(e => {
      this.emitterEvent = e
      this.emitterEvent.next(this._event);
    });

    this.observableArtikel = Observable.create(e => {
      this.emitterArtikel = e
      this.emitterArtikel.next(this._artikels);
    });

    this.observableVerkaeufer = Observable.create(e => {
      this.emitterVerkaeufer = e
      this.emitterVerkaeufer.next(this._verkaeufers);
    });

    this.observableBelege = Observable.create(e => {
      this.emitterBelege = e
      this.emitterBelege.next(this._belege);
    });

  }

  setReferences(eventId: string) {
    this._artikels = []
    this._belege = []
    this._verkaeufers = []

    this.initEvent()
    this.initData()
  }

  event(eventId: string): Observable<VerkausEvent> {
    return this.observablEvent
  }

  updateEvent(obj: VerkausEvent) {
    this._event = obj  //TODO: Execute event...
    this.emitterEvent.next(this._event);
  }

  createEvent(event: VerkausEvent): string {
    event.mId = this.zahlenGenerator.generateCodes(1, this._event.mId)[0]
    event.id = event.mId
    this._event = event
    return this._event.id
  }

  // *** A R T I K E L *** //

  artikels(eventId: string): Observable<Artikel[]> {
    return this.observableArtikel
  }

  createArtikel(obj: Artikel): string {
    obj.id = "1111"
    if (this._artikels.length > 0)
      obj.id = this.zahlenGenerator.generateCodes(1, this._artikels[this._artikels.length - 1].id)[0] //TODO: Case, if list is empty
    this._artikels.push(obj)
    this.emitterArtikel.next(this._artikels);
    return obj.id
  }

  updateArtikel(obj: Artikel) {
    let tmpArtikels: Artikel[] = []
    for (let artikel of this._artikels) {
      tmpArtikels.push(obj.qrCode == artikel.qrCode ? obj : artikel)
    }
    this._artikels = tmpArtikels

    this.emitterArtikel.next(this._artikels);

  }

  deleteArtikel(id: string) {
    let _index: number = -1
    for (let i = 0; i < this._artikels.length; i++) {
      if (this._artikels[i].id == id) {
        _index = i
        break
      }
    }

    if (_index > -1) {
      this._artikels.splice(_index, 1);
      this.emitterArtikel.next(this._artikels);
    }

  }


  // *** V E R K A E U F E R *** //

  verkaeufers(eventId: string): Observable<Verkaeufer[]> {
    console.log("verkaeufers", this._verkaeufers)

    return this.observableVerkaeufer
  }

  createVerkaeufer(obj: Verkaeufer): string {
    obj.id = "1111"

    if (this._verkaeufers.length > 0)
      obj.id = this.zahlenGenerator.generateCodes(1, this._verkaeufers[this._verkaeufers.length - 1].id)[0] //TODO: Case, if list is empty
    this._verkaeufers.push(obj)
    this.emitterVerkaeufer.next(this._verkaeufers);
    return obj.id
  }

  updateVerkaeufer(obj: Verkaeufer) {

    console.log("updateVerkaeufer 1", obj, this._verkaeufers)
    let tmpVerkaeufers: Verkaeufer[] = []
    for (let verkaeufer of this._verkaeufers) {
      tmpVerkaeufers.push(obj.verkaeuferId == verkaeufer.verkaeuferId ? obj : verkaeufer)
    }
    this._verkaeufers = tmpVerkaeufers

    console.log("updateVerkaeufer 2", obj, this._verkaeufers)
    this.emitterVerkaeufer.next(this._verkaeufers);
  }

  deleteVerkaeufer(id: string) {

    console.log("deleteVerkaeufer", this._verkaeufers)
    let _index: number = -1
    for (let i = 0; i < this._verkaeufers.length; i++) {
      if (this._verkaeufers[i].id == id) {
        _index = i
        break
      }
    }

    if (_index > -1) {
      this._verkaeufers.splice(_index, 1);
      this.emitterVerkaeufer.next(this._verkaeufers);
    }
  }


  // *** B E L E G *** //

  belege(eventId: string): Observable<Beleg[]> {
    return this.observableBelege
  }

  createBeleg(obj: Beleg): string {
    obj.id = "1111"

    if (this._belege.length > 0)
      obj.id = this.zahlenGenerator.generateCodes(1, this._belege[this._belege.length - 1].id)[0] //TODO: Case, if list is empty
    this._belege.push(obj)
    this.emitterBelege.next(this._belege);
    return obj.id
  }

  updateBeleg(obj: Beleg) {
    let tmpBelege: Beleg[] = []
    for (let beleg of this._belege) {
      tmpBelege.push(obj.belegnummer == beleg.belegnummer ? obj : beleg)
    }
    this._belege = tmpBelege

    this.emitterBelege.next(this._belege);
  }

  deleteBeleg(id: string) {
    let _index: number = -1
    for (let i = 0; i < this._belege.length; i++) {
      if (this._belege[i].id == id) {
        _index = i
        break
      }
    }

    if (_index > -1) {
      this._belege.splice(_index, 1);
      this.emitterBelege.next(this._belege);
    }
  }








  private initEvent() {
    let kasse_1: Kasse = {
      name: "Kasse 1",
      currentBelegId: TEMP_BELEG_ID + "kasse_1",
      kassenId: "kasse_1",
      suffix: "_1"
    }

    let kasse_2: Kasse = {
      name: "Kasse 2",
      currentBelegId: TEMP_BELEG_ID + "kasse_2",
      kassenId: "kasse_2",
      suffix: "_2"
    }

    let tasche: EinmalArtikel = {
      name: "Tasche",
      preis: 5
    }

    this._event = {
      id: "1111C",
      mId: "1111C",
      name: "Zweite Liebe",
      lastUsedEtikettenCode: "1111",
      lastUsedBelegCode: "1111",
      lastVerkaeuferId: "1111",
      timestamp_von: 1668236400000,
      timestamp_bis: 1668272400000,
      locationName: "Kenzingen Gymnasium",
      city: "Kenzingen",
      locationAdresse: "Breslauer Str. 13, 79341 Kenzingen",
      organisatorId: "N/A",
      kassen: [kasse_1, kasse_2],
      einmalArtikels: [tasche]
    }
  }

  private initData() {

    let index: number = 0
    let codes: string[] = this.zahlenGenerator.generateCodes(500,"1111")
    let verkaeuferIds: string[] = []

    this.http.get('/assets/demo-data/verkaeufers.json').subscribe((__verkaeufers: Verkaeufer[]) => {
      for (let verkaeufer of __verkaeufers) {
        verkaeufer.verkaeuferId = codes[index++]
        verkaeuferIds.push(verkaeufer.verkaeuferId)
        verkaeufer.eventId = this.config.currentEventId
        this.createVerkaeufer(verkaeufer)
      }
      console.log("initDataVerkaeufer", this._verkaeufers)

      //*** Artikel ***
      index = 0

      this.http.get('/assets/demo-data/artikels.json').subscribe((__artikels: Artikel[]) => {
        for (let artikel of __artikels) {
          artikel.qrCode = codes[index++]
          artikel.verkaeuferId = verkaeuferIds[Math.floor(Math.random() * verkaeuferIds.length)];
          artikel.status = ArtikelStatus.NEU
          artikel.uploadTimestamp = new Date().getTime()
          artikel.category = ""
          artikel.eventId = this.config.currentEventId
          if (artikel.preis == -1) artikel.preis = Math.floor(Math.random() * 140) + 1
          this.createArtikel(artikel)
        }
      })
    })
  }

}
