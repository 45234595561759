import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Artikel, VerkausEvent, Verkaeufer, Beleg, Database } from '../models/model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { PATH_ARTIKELS, PATH_EVENTS, PATH_VERKAEUFERS, PATH_BELEGE } from 'src/app/models/constants';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService implements Database{

  private artikelListeRef: AngularFirestoreCollection<Artikel>;
  private verkaeuferListeRef: AngularFirestoreCollection<Verkaeufer>;
  private belegListeRef: AngularFirestoreCollection<Beleg>;
  private eventListeRef: AngularFirestoreCollection<VerkausEvent>;

  constructor(private afs: AngularFirestore, private config: ConfigService) {
    this.eventListeRef = afs.collection<VerkausEvent>(this.config.dbVersion + PATH_EVENTS + "/list");
  }

  setReferences(eventId: string) {
    this.artikelListeRef = this.afs.collection<Artikel>(this.config.dbVersion + PATH_ARTIKELS + '/' + eventId);
    this.verkaeuferListeRef = this.afs.collection<Verkaeufer>(this.config.dbVersion + PATH_VERKAEUFERS + '/' + eventId);
    this.belegListeRef = this.afs.collection<Beleg>(this.config.dbVersion + PATH_BELEGE + '/' + eventId);
  }

  event(eventId: string): Observable<VerkausEvent> {
    return this.afs.doc<VerkausEvent>(this.config.dbVersion + PATH_EVENTS + '/list/' + eventId).valueChanges()
  }

  updateEvent(obj: VerkausEvent) {
    return this.eventListeRef.doc<VerkausEvent>('/' + obj.mId).update(obj)
  }

  createEvent(event: VerkausEvent): string {
    event.mId = this.afs.createId();
    this.eventListeRef.doc<VerkausEvent>('/' + event.mId).set({ ...event }) //Alternativ: this.eventListeRef.add(event);
    return event.id
  }

  // *** A R T I K E L *** //

  artikels(eventId: string): Observable<Artikel[]> {
    return this.afs.collection<Artikel>(this.config.dbVersion + PATH_ARTIKELS + '/' + eventId, ref => ref.where('eventId', '==', eventId)).valueChanges()
  }

  createArtikel(obj: Artikel): string {
    obj.id = this.afs.createId()
    this.artikelListeRef.doc<Artikel>('/' + obj.id).set({ ...obj })
    return obj.id
  }

  updateArtikel(obj: Artikel) {
    return this.artikelListeRef.doc<Artikel>('/' + obj.id).update(obj)
  }

  deleteArtikel(id: string) {
    return this.artikelListeRef.doc<Artikel>('/' + id).delete()
  }


  // *** V E R K A E U F E R *** //

  verkaeufers(eventId: string): Observable<Verkaeufer[]> {
    return this.afs.collection<Verkaeufer>(this.config.dbVersion + PATH_VERKAEUFERS + '/' + eventId, ref => ref.where('eventId', '==', eventId)).valueChanges()
  }

  createVerkaeufer(obj: Verkaeufer): string {
    obj.id = this.afs.createId()
    this.verkaeuferListeRef.doc<Verkaeufer>('/' + obj.id).set({ ...obj })
    return obj.id
  }

  updateVerkaeufer(obj: Verkaeufer) {
    return this.verkaeuferListeRef.doc<Verkaeufer>('/' + obj.id).update(obj)
  }

  deleteVerkaeufer(id: string) {
    return this.verkaeuferListeRef.doc<Verkaeufer>('/' + id).delete()
  }


  // *** B E L E G *** //

  belege(eventId: string): Observable<Beleg[]> {
    return this.afs.collection<Beleg>(this.config.dbVersion + PATH_BELEGE + '/' + eventId, ref => ref.where('eventId', '==', eventId)).valueChanges()
  }

  createBeleg(obj: Beleg): string {
    if (!obj.id) {
      obj.id = this.afs.createId()
    }

    this.belegListeRef.doc<Beleg>('/' + obj.id).set({ ...obj })
    return obj.id
  }

  updateBeleg(obj: Beleg) {
    return this.belegListeRef.doc<Beleg>('/' + obj.id).update(obj)
  }

  deleteBeleg(id: string) {
    return this.belegListeRef.doc<Beleg>('/' + id).delete()
  }
}
