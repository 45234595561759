import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from "@angular/core";
import { ArtikelService } from 'src/app/services/artikel.service';
import { ArtikelComponent } from 'src/app/components/artikel/artikel.component';

@Injectable()
export class ArtikelScannerGuard implements CanDeactivate<ArtikelComponent> {

    constructor(private artikelService: ArtikelService) { }

    canDeactivate(component: ArtikelComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        this.artikelService.stopScan()

        return true
    }

}
