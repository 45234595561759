import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ComponentModule } from 'src/app/components/component.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ArtikelScannerGuard } from 'src/app/guards/artikel-scanner.guard';
import { KassenScannerGuard } from 'src/app/guards/kassen-scanner.guard';
import { AuswertungsGuard } from 'src/app/guards/auswertung.guard';


export const firebaseConfig = {
  apiKey: "AIzaSyDmo5bijxTj_F1B8S3LvwSvGG_KFwqmuGI",
  authDomain: "zweiteliebe-d9286.firebaseapp.com",
  projectId: "zweiteliebe-d9286",
  storageBucket: "zweiteliebe-d9286.appspot.com",
  messagingSenderId: "468672845338",
  appId: "1:468672845338:web:80e3a7792cd60f4484a3a3",
  measurementId: "G-GZK3MVGSWW"
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireAuthModule,
    FormsModule,
    ScrollingModule,
    ComponentModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, DatePipe, ArtikelScannerGuard, KassenScannerGuard, AuswertungsGuard],
  bootstrap: [AppComponent],
})
export class AppModule { }

