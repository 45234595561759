import { MARGE } from '../models/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import html2pdf from 'html2pdf.js'
import { Beleg, Verkaeufer, ArtikelStatus, Zahlungsart } from 'src/app/models/model';
import { ConfigService } from 'src/app/services/config.service';
import { ArtikelService } from 'src/app/services/artikel.service';
import { DatePipe } from '@angular/common'
import { EventService } from 'src/app/services/event.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  baseUrl: string = ""
  yearAsString: string = ""

  constructor(private loadingController: LoadingController, private config: ConfigService, private http: HttpClient, public artikelService: ArtikelService, public datepipe: DatePipe, public eventService: EventService) {
    this.baseUrl = this.config.baseUrl
    this.yearAsString = this.datepipe.transform(new Date(), 'dd.MM.yyyy');
  }

  async downloadXlsx(verkaeuferName: string, codes: string[]) {
    const loading = await this.loadingController.create({
      message: 'Dokument wird generiert. Dies kann bis zu 30s dauern...',
      duration: 20000
    });
    await loading.present();

    const baseUrl = this.baseUrl + '/xlsx/etiketten';
    this.http.post(baseUrl, codes, {
      responseType: "blob"
    }).subscribe(
      (response: any) => {
        console.log(response)
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', verkaeuferName.replace(" ", "") + "-" + codes[0] + ".xlsx");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.loadingController.dismiss()
      })
  }

  downloadDocx(verkaeuferName: string, codes: string[]): void {

    const baseUrl = this.baseUrl + '/docx/etiketten';
    this.http.post(baseUrl, codes, {
      responseType: "blob"
    }).subscribe(
      (response: any) => {
        console.log(response)
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', verkaeuferName.replace(" ", "") + "-" + codes[0] + ".docx");
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
      )
  }

  async downloadDocxV2(verkaeuferName: string, verkaeuferId, artikels) {
    const maxArtikelPerDoc = 216;
    const baseUrl = this.baseUrl + '/docx/etiketten/v2';

    if (artikels.length == 0) return;

    const loading = await this.loadingController.create({
      message: 'Dokument wird generiert. Dies kann bis zu 30s dauern...',
      duration: 20000
    });

    await loading.present();
    const chunks = [];
    for (let i = 0; i < artikels.length; i += maxArtikelPerDoc) {
        chunks.push(artikels.slice(i, i + maxArtikelPerDoc));
    }

    chunks.forEach((chunk, index) => {
        this.http.post(baseUrl, chunk, {
            responseType: "blob"
        }).subscribe(
            (response: any) => {
               console.log(response)
                let dataType = response.type;
                let binaryData = [];
                binaryData.push(response);
                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                downloadLink.setAttribute('download', `${verkaeuferName.replace(" ", "")}-${verkaeuferId}-${index + 1}.docx`);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                this.loadingController.dismiss()
            }
        );
    });
}


  downloadXlsxOverview(artikels: any): void {

    const baseUrl = this.baseUrl + '/xlsx/overview';
    this.http.post(baseUrl, artikels, {
      responseType: "blob"
    }).subscribe(
      (response: any) => {
        console.log(response)
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', "artikel-uebersicht.xlsx");
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
      )
  }

  //https://github.com/eKoopmans/html2pdf.js
  //https://ekoopmans.github.io/html2pdf.js/

  createBelegDoc(beleg: Beleg) {
    let locationDate = this.eventService.currentEvent.city + ", " + this.yearAsString

    var opt = {
      margin: 0.6,
      image: { type: "jpg", quality: 0.95 },
      html2canvas: { scale: 2 },
      pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    let datestring: string = new Date().toLocaleString()
    let betrag: string = this.config.toCurrencyString(beleg.gesamtpreis)
    let zahlungsart: string = beleg.zahlungsart == Zahlungsart.BAR ? "Betrag wurde bar bezahlt." : "Betrag wurde per Karte bezahlt."

    let artikels = ""
    for (let artikel of beleg.artikels) {
      artikels += `
      <tr>
      <td style="text-align: center;">${artikel.qrCode}</td>
      <td>&nbsp;${artikel.name}</td>
      <td style="text-align: right;">${this.config.toCurrencyString(artikel.preis)} &euro;&nbsp;</td>
      <td style="text-align: center;">1</td>
      <td style="text-align: right;">${this.config.toCurrencyString(artikel.preis)} &euro;&nbsp;</td>
      </tr>
      `
    }

    for (let einmalartikel of beleg.einmalArtikels) {
      artikels += `
      <tr>
      <td style="text-align: center;"></td>
      <td>&nbsp;${einmalartikel.name}</td>
      <td style="text-align: right;">${this.config.toCurrencyString(einmalartikel.preis)} &euro;&nbsp;</td>
      <td style="text-align: center;">1</td>
      <td style="text-align: right;">${this.config.toCurrencyString(einmalartikel.preis)} &euro;&nbsp;</td>
      </tr>
      `
    }

    html2pdf().set(opt).from(`
      <div>
      <table style="border-collapse: collapse; width: 100%;" border="0">
      <tbody>
      <tr>
      <td style="width: 40%;"><img src="./assets/logo.png"  /></td>
      <td style="text-align: right;">
      <p>Quittung: ${beleg.belegnummer}</p>
      <p>${datestring}</p>
      <p>Romina Degenkolb &amp; Tina Fiedler GbR</p>
      </td>
      </tr>
      </tbody>
      </table>
      <p>&nbsp;</p>
      <table style="border-collapse: collapse; width: 100%; height: 108px;" border="1">
      <tbody>
      <tr style="text-align: center; font-weight: bold;">
      <td>Artikel ID</td>
      <td>Bezeichnung</td>
      <td>Preis</td>
      <td>Anzahl</td>
      <td>Gesamtpreis</td>
      </tr>
      ${artikels}
      </tbody>
      </table>
      <p>&nbsp;</p>
      <p style="text-align: right;"><strong>Nettobetrag: <span style="text-decoration: underline;">${betrag} &euro;&nbsp;</span></strong></p>
      <p style="text-align: right;"><em>Umsatzsteuerfreie Leistungen gem&auml;&szlig; &sect;19 UStG</em>.</p>
      <p>&nbsp;</p>
      <p>-------------------------------------</p>
      <p>${locationDate}</p>
      <br />
      <p>${zahlungsart}</p>

    </div>`
    ).save(beleg.belegnummer + "-Quittung.pdf");

  }

  createVerkaeuferExport(verkaeufer: Verkaeufer) {
    let locationDate = this.eventService.currentEvent.city + ", " + this.yearAsString

    var opt = {
      margin: 0.6,
      image: { type: "jpg", quality: 0.95 },
      html2canvas: { scale: 2 },
      pagebreak: { before: '.beforeClass', after: ['#after1', '#after2'], avoid: 'img' },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    let datestring = new Date().toLocaleString()  // = inkl. Uhrzeit
    let gesamtbetrag: number = 0

    let artikels = ""
    for (let artikel of this.artikelService.sortForAuswertung(this.artikelService.findByVerkaeuferId(verkaeufer.verkaeuferId))) {
      //for (let artikel of this.artikelService.findByVerkaeuferId(verkaeufer.verkaeuferId)) {
      artikels += `
      <tr>
      <td style="text-align: center;">${artikel.qrCode}</td>
      <td>&nbsp;${artikel.name}</td>
      <td style="text-align: right;">${this.config.toCurrencyString(artikel.preis)} &euro;&nbsp;</td>
      <td style="text-align: center;">1</td>
      <td style="text-align: right;">${artikel.status}</td>
      </tr>
      `
      if (artikel.status == ArtikelStatus.VERKAUFT) {
        gesamtbetrag += (+artikel.preis)
      }
    }

    gesamtbetrag = +gesamtbetrag
    let auszahlungsbetrag = gesamtbetrag * (1 - MARGE)
    let marge = gesamtbetrag * MARGE

    html2pdf().set(opt).from(`
      <div>
      <table style="border-collapse: collapse; width: 100%;" border="0">
      <tbody>
      <tr>
      <td style="width: 40%;"><img src="./assets/logo.png"  /></td>
      <td style="text-align: right;">
      <p style="margin-top: 0px; margin-bottom: 5px;">Romina Degenkolb &amp; Tina Fiedler GbR</p>
      <p style="margin-top: 0px; font-weight: bold;margin-bottom: 5px;">Abrechnung R${verkaeufer.verkaeuferId}</p>
      <p style="margin-top: 0px; margin-bottom: 5px;">Ort der Leistung: Hochzeitsbasar in ${this.eventService.currentEvent.city}, ${this.yearAsString}</p>
      <p style="margin-top: 0px; margin-bottom: 5px;">Verkäufer: ${verkaeufer.name}</p>
      </td>
      </tr>
      </tbody>
      </table>

      <table style="border-collapse: collapse; width: 100%; height: 108px;" border="1">
      <tbody>
      <tr style="text-align: center; font-weight: bold;">
      <td>Artikel ID</td>
      <td>Bezeichnung</td>
      <td>Preis</td>
      <td>Anzahl</td>
      <td>Status</td>
      </tr>
      ${artikels}
      </tbody>
      </table>

      <br />

      <table border="0" align="right" style="width: 70%;">
        <tr >
          <td style="padding-top: 5px;padding-bottom: 5px;"><strong>Gesamteinnahmen:</strong></td>
          <td style="text-align: right; padding-bottom: 5px;padding-top: 5px;"><strong>${this.config.toCurrencyString(gesamtbetrag)} &euro;&nbsp;</strong></td>
        </tr>
        <tr>
        <td><strong>Gebühren (15%)*:</strong></td>
        <td style="text-align: right;"><strong>-${this.config.toCurrencyString(marge)} &euro;&nbsp;</strong></td>
        </tr>
        <tr>
          <td style="padding-top: 5px;padding-bottom: 5px;">Ausstellergebühr*:</td>
          <td style="text-align: right; padding-top: 5px;padding-bottom: 5px;">-10.00 &euro;&nbsp;</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="padding-bottom: 5px;">Ausstellergebühr bereits beglichen:</td>
          <td style="text-align: right; padding-bottom: 5px;">10.00 &euro;&nbsp;</td>
        </tr>

        <tr style="text-decoration: underline; margin-top: 5px;">
        <td style="padding-top: 5px;"><strong>Auszahlungsbetrag:</strong></td>
        <td style="text-align: right; padding-top: 5px; text-decoration: underline"><strong>${this.config.toCurrencyString(auszahlungsbetrag)} &euro;&nbsp;</strong></td>
      </tr>
        <tr>
        <td colspan="2"><span style="font-size:small; text-align: right;"><em>*Umsatzsteuerfreie Leistungen gem&auml;&szlig; &sect;19 UStG</em>.</span></td>
      </tr>
      </table>

      <div style="height: 185px;">&nbsp;</div>
      <p>-------------------------------------</p>
      <p>${locationDate}</p>

    </div>`
    ).save(verkaeufer.name.replace(" ", "") + "-" + verkaeufer.verkaeuferId + "-Auswertung.pdf");

  }

}
