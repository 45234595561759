import { Pipe, PipeTransform } from '@angular/core';
import { Verkaeufer } from 'src/app/models/model';

@Pipe({
  name: 'verkaeuferFilter',
  pure: false
})
export class VerkaeuferFilterPipe implements PipeTransform {

  transform(verkaeufer: Verkaeufer[], verkaeuferId: string, searchstring: string): Verkaeufer[] {
    if (!verkaeufer) return []
    return verkaeufer.filter(v =>
      (
        (verkaeuferId == null || v.verkaeuferId == verkaeuferId) &&
        (searchstring == null || searchstring.trim().length == 0 || this.search(v.name, searchstring) || this.foundBySearchstring(v, searchstring))
      )
    );
  }

  foundBySearchstring(verkaeufer: Verkaeufer, searchstring: string): boolean {
    return this.search(verkaeufer.name, searchstring) ||
      this.search(verkaeufer.verkaeuferId, searchstring) ||
      this.search(verkaeufer.email, searchstring) ||
      this.search(verkaeufer.phone, searchstring) ||
      this.search(verkaeufer.street, searchstring) ||
      this.search(verkaeufer.plz, searchstring) ||
      this.search(verkaeufer.city, searchstring) ||
      this.search(verkaeufer.country, searchstring) ||
      this.search(verkaeufer.note, searchstring)
  }

  private search(s: string, searchstring: string): boolean {
    return s != undefined && s.toLowerCase().trim().indexOf(searchstring.toLowerCase().trim()) != -1
  }

}
