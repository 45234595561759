import { EtikettenService } from './services/etiketten.service';
import { Component } from '@angular/core';
import { EventService } from './services/event.service';
import { ArtikelService } from 'src/app/services/artikel.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { ArtikelComponent } from 'src/app/components/artikel/artikel.component';
import { LocationStrategy } from '@angular/common';
import { ZahlenGeneratorService } from 'src/app/services/zahlen-generator.service';
import { ImportService } from 'src/app/services/import.service';
import { ExportService } from 'src/app/services/export.service';
import { Artikel } from 'src/app/models/model';
import { BelegService } from 'src/app/services/beleg.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Übersicht', url: '/event', icon: 'planet' },
    { title: 'Excel generieren', url: '/etiketten', icon: 'newspaper' },
    { title: 'Excel Import', url: '/import', icon: 'cloud-upload' },
    { title: 'Verkäufer', url: '/verkaeufer', icon: 'person' },
    { title: 'Artikel', url: '/artikel', icon: 'bag-handle' },
    { title: 'Kasse', url: '/kasse', icon: 'wallet' },
    { title: 'Auswertungen', url: '/auswertungen', icon: 'analytics' },
    { title: 'Einstellungen', url: '/settings', icon: 'settings' },
  ];

  constructor(private location: LocationStrategy, public router: Router, public eventService: EventService, public config: ConfigService,
    public artikelService: ArtikelService, public belegService: BelegService, public etikettenService: EtikettenService, public zahlenGeneratorService: ZahlenGeneratorService,
    public importService: ImportService, public exportService: ExportService, private authService: AuthService) {

    //  this.eventService.addEvent()

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    eventService.selectEvent(this.config.currentEventId)
    //TODO: Wait until all is loaded
    this.authService.user.subscribe((val: any) => {
      if (val && val.uid) {
        this.config.loggedIn = true
        router.navigateByUrl("/event")
      } else {
        this.config.loggedIn = false
        router.navigateByUrl("/login")
      }
    })
  }

}
