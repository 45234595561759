import { NgModule } from '@angular/core';
import { VerkaeuferSortPipe } from 'src/app/pipes/verkaeufer-sort.pipe';
import { BelegSortPipe } from 'src/app/pipes/beleg-sort.pipe';
import { EditVerkaeuferComponent } from 'src/app/components/edit-verkaeufer/edit-verkaeufer.component';
import { ArtikelComponent } from 'src/app/components/artikel/artikel.component';
import { EditArtikelComponent } from 'src/app/components/edit-artikel/edit-artikel.component';
import { VerkaeuferFilterPipe } from 'src/app/pipes/verkaeufer-filter.pipe';
import { ArtikelFilterPipe } from 'src/app/pipes/artikel-filter.pipe';
import { ArtikelSortPipe } from 'src/app/pipes/artikel-sort.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChartComponent } from 'src/app/components/chart/chart.component';


@NgModule({
  exports: [CommonModule, FormsModule, VerkaeuferSortPipe, BelegSortPipe, EditVerkaeuferComponent, ArtikelComponent, EditArtikelComponent, VerkaeuferFilterPipe,
    ArtikelFilterPipe, ArtikelSortPipe, ScrollingModule, ChartComponent
  ],
  imports: [CommonModule, FormsModule, ScrollingModule],
  declarations: [VerkaeuferSortPipe, BelegSortPipe, EditVerkaeuferComponent, ArtikelComponent, EditArtikelComponent, VerkaeuferFilterPipe,
    ArtikelFilterPipe, ArtikelSortPipe, ChartComponent]
})
export class ComponentModule { }
