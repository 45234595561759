import { Pipe, PipeTransform } from '@angular/core';
import { Verkaeufer, SortFields, SortDirection } from 'src/app/models/model';
import { ConfigService } from 'src/app/services/config.service';

@Pipe({
  name: 'verkaeuferSort'
})
export class VerkaeuferSortPipe implements PipeTransform {

  constructor(private config: ConfigService) {

  }

  transform(verkaeufer: Verkaeufer[], sortBy: SortFields, sortDirection: SortDirection): Verkaeufer[] {
    if (!verkaeufer) return []
    return verkaeufer.sort((a: Verkaeufer, b: Verkaeufer) => {
      switch (sortBy) {
        case SortFields.CODE: return this.config.compareStrings(a.verkaeuferId, b.verkaeuferId, sortDirection)
        case SortFields.NAME:
          if (a.name == "Neuer Verkäufer" && b.name != "Neuer Verkäufer") return -1;
          if (b.name == "Neuer Verkäufer" && a.name != "Neuer Verkäufer") return 1;
          return this.config.compareStrings(a.name, b.name, sortDirection)
        case SortFields.EMAIL: return this.config.compareStrings(a.email, b.email, sortDirection)
      }
      return 0
    });
  }

}
