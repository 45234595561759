import { Injectable } from '@angular/core';
import { Artikel, ArtikelStatus, Verkaeufer } from '../models/model';
import { DataService } from './data.service';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransferArtikelService {

  constructor(private http: HttpClient, private dataService: DataService, private configService: ConfigService) {

  }

  //////////////////////////////////
  // INSERT PREVIOUS DATA
  //////////////////////////////////

  public addVerkaeuferAndArtikel() {
    this.http.get('/assets/prev-data/verkaeufers.json').subscribe((__verkaeufers: Verkaeufer[]) => {
      for (let verkaeufer of __verkaeufers) {
        verkaeufer.eventId = this.configService.currentEventId
        this.dataService.createVerkaeufer(verkaeufer)
      }
      console.log("alte verkaeufer angelegt")

      //*** Artikel ***
      this.http.get('/assets/prev-data/artikels.json').subscribe((__artikels: Artikel[]) => {
        for (let artikel of __artikels) {
          artikel.eventId = this.configService.currentEventId
          this.dataService.createArtikel(artikel)
        }
        console.log("alte artikel angelegt")
      })
    })
  }




  //////////////////////////////////
  // EXPORT JSON
  //////////////////////////////////
  verkaeuferIds = [
    "111HM",
    "111GR",
    "111JD",
    "1118K",
    "111AG",
    "111BC",
    "111K9",
    "1117P"
  ]

  JSON_EXPORT_ENABLED: boolean = false
  public exportJsons(artikels: Artikel[], verkaeufers: Verkaeufer[]) {
    if (!this.JSON_EXPORT_ENABLED) return;

    if (verkaeufers != null) {
      let _verkaeufer = []
      for (let v of verkaeufers) {
        if (this.verkaeuferIds.includes(v.verkaeuferId)) {
          _verkaeufer.push(v)
        }
      }
      this.downloadJsonContent(_verkaeufer, "verkaeufers")
    }

    if (artikels != null) {

      let _artikel = []
      for (let a of artikels) {
        if (this.verkaeuferIds.includes(a.verkaeuferId)) {
          if (a.status != ArtikelStatus.VERKAUFT) {
            a.status = ArtikelStatus.NEU
            _artikel.push(a)
          }
        }
      }
      this.downloadJsonContent(_artikel, "artikels")
    }
  }

  private downloadJsonContent(liste, name) {

    const jsonContent = JSON.stringify(liste, null, 2); // Pretty print with 2 spaces

    const blob = new Blob([jsonContent], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name + '.json';
    link.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);

  }
}
