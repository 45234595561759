import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Verkaeufer } from '../models/model';
import { DataService } from './data.service';
import { TransferArtikelService } from './transfer-artikel.service';

@Injectable({
    providedIn: 'root'
})
export class VerkaeuferService {

    verkaeufers: Verkaeufer[]
    currentSubscription: Subscription
    currentVerkaeufer: Verkaeufer           // wenn im Bearbeitungsmodal

    constructor(private dataService: DataService, private transferArtikelService: TransferArtikelService) { }

    get verkaeuferCodes(): string[] {
        return this.verkaeufers.map(v => v.verkaeuferId);
    }

    add(obj: Verkaeufer): string {
        let id: string = this.dataService.createVerkaeufer(obj)
        console.debug("Verkaeufer mit ID " + id + " wurde hinzugefügt.")
        return id
    }

    update(obj: Verkaeufer) {
        this.dataService.updateVerkaeufer(obj)
        console.debug("Verkaeufer aktualisiert", obj)
    }

    delete(id: string) {
        this.dataService.deleteVerkaeufer(id)
        console.debug("Verkaeufer gelöscht")
    }

    copy(obj: Verkaeufer): Verkaeufer {
        return Object.assign({}, obj)
    }

    findByVerkaeuferId(verkaeuferId: string): Verkaeufer {
        if(!verkaeuferId) return undefined
        return this.verkaeufers.find((obj: Verkaeufer) => {
            return obj.verkaeuferId == verkaeuferId;
        });
    }

    findByName(name: string): Verkaeufer {
        if(!name) return undefined
        return this.verkaeufers.find((obj: Verkaeufer) => {
            return obj.name.toUpperCase() == name.toUpperCase();
        });
    }

    eventChanged(eventId: string) {
        if (this.currentSubscription) this.currentSubscription.unsubscribe();

        this.currentSubscription = this.dataService.verkaeufers(eventId).subscribe((verkaeufers: Verkaeufer[]) => {
            console.debug("Aktualisierte Verkaeufer-Liste vom Server", verkaeufers)
            this.verkaeufers = verkaeufers

            this.transferArtikelService.exportJsons(null, this.verkaeufers)
        })
    }











}



















