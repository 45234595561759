import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Artikel, VerkausEvent, Verkaeufer, Beleg, Database } from '../models/model';
import { ConfigService } from 'src/app/services/config.service';
import { FirebaseDataService } from 'src/app/services/firebase-data.service';
import { LocalDataService } from 'src/app/services/local-data.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  database: Database

  constructor(public config: ConfigService, public firebaseDataService: FirebaseDataService, public localDataService: LocalDataService) {
    this.initDb()
  }

  initDb() {
    if(this.config.demo) {
      this.database = this.localDataService
    }else {
      this.database = this.firebaseDataService
    }
  }

  setReferences(eventId: string) {
    this.database.setReferences(eventId)
  }

  event(eventId: string): Observable<VerkausEvent> {
    return this.database.event(eventId)
  }

  updateEvent(obj: VerkausEvent) {
    return this.database.updateEvent(obj)
  }

  createEvent(event: VerkausEvent): string {
    return this.database.createEvent(event)
  }

  // *** A R T I K E L *** //

  artikels(eventId: string): Observable<Artikel[]> {
    return this.database.artikels(eventId)
  }

  createArtikel(obj: Artikel): string {
    return this.database.createArtikel(obj)
  }

  updateArtikel(obj: Artikel) {
    return this.database.updateArtikel(obj)
  }

  deleteArtikel(id: string) {
    return this.database.deleteArtikel(id)
  }


  // *** V E R K A E U F E R *** //

  verkaeufers(eventId: string): Observable<Verkaeufer[]> {
    return this.database.verkaeufers(eventId)
  }

  createVerkaeufer(obj: Verkaeufer): string {
    return this.database.createVerkaeufer(obj)
  }

  updateVerkaeufer(obj: Verkaeufer) {
    return this.database.updateVerkaeufer(obj)
  }

  deleteVerkaeufer(id: string) {
    return this.database.deleteVerkaeufer(id)
  }

  // *** B E L E G *** //

  belege(eventId: string): Observable<Beleg[]> {
    return this.database.belege(eventId)
  }

  createBeleg(obj: Beleg): string {
    return this.database.createBeleg(obj)
  }

  updateBeleg(obj: Beleg) {
    return this.database.updateBeleg(obj)
  }

  deleteBeleg(id: string) {
    return this.database.deleteBeleg(id)
  }
}
