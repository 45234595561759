import { Pipe, PipeTransform } from '@angular/core';
import { Artikel, ArtikelStatus } from 'src/app/models/model';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';

@Pipe({
  name: 'artikelFilter',
  pure: false
})
export class ArtikelFilterPipe implements PipeTransform {

  constructor(private verkaeuferService: VerkaeuferService) {

  }

  /*
    toast analyse auswetungen ??
    ml category
    d3

    service klassen
    result objekt
    beleg kontrollieren
  */

  //meisten abfragen sollten in service sein fèr unit tests
  //kudnen/Verkàufer in databases und èbewrall deployen
  //Mehr Loading COntroller zB bei auswerungenetiketten
  //grafische auswertung in XLSX
  //GBR und nicht www xlsx von locl, overview xlsx ausbauen
  //Etiketten mit seitenzahl
  //TODO: 2. Kasse testen (vor allem, wenn Beleg mit Kasse 2 gespeichert, dann mit Kasse 1 laden, was ändern und wieder speichern)

  //Scroll down wenn beleg gewäht und co.
  //Marge variabel gestalten
  //Reports: wer die Top Verdiener waren, wer die meisten Sachen verkauft hat, wer am meisten gebracht hat
  // Code aufröuzmen
  //TODO: PDF Generierung auf server
  //Logout Button
  //Verkaeufernummer und Belegnummern können länger sein (sollen auch)
  //Event 9.10 10-16 Uhr

  transform(artikels: Artikel[], verkaeuferId: string, searchstring: string, status: ArtikelStatus): Artikel[] {
    if (!artikels) return []
    return artikels.filter(artikel =>
      ((verkaeuferId == null || artikel.verkaeuferId == verkaeuferId) &&
        (searchstring == null || searchstring.trim().length == 0 || this.foundBySearchstring(artikel, searchstring)) &&
        (status == null || artikel.status == status))
    );
  }

  foundBySearchstring(artikel: Artikel, searchstring: string): boolean {
    let verkaeufer = this.verkaeuferService.findByVerkaeuferId(artikel.verkaeuferId)
    let verkaeufername = verkaeufer ? verkaeufer.name : ""

    return this.search(artikel.name, searchstring) ||
      this.search(artikel.qrCode, searchstring) ||
      this.search(verkaeufername, searchstring) ||
      this.search(artikel.verkaeuferId, searchstring) ||
      this.search(artikel.status, searchstring)
  }

  private search(s: string, searchstring: string): boolean {
    return s != undefined && s.toLowerCase().trim().indexOf(searchstring.toLowerCase().trim()) != -1
  }
}
