import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { Artikel, ArtikelStatus } from 'src/app/models/model';
import { ConfigService } from 'src/app/services/config.service';
import { ArtikelService } from 'src/app/services/artikel.service';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';
import { ZahlenGeneratorService } from 'src/app/services/zahlen-generator.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  arrayBuffer: any;
  file: File;
  artikels: any[] = []

  constructor(private config: ConfigService, private verkaeuferSerivce: VerkaeuferService, private artikelService: ArtikelService,
    private zahlenGeneratorService: ZahlenGeneratorService) { }

  incomingfile(event) {
    this.file = event.target.files[0];
    this.artikels = []
  }

  async upload(eventId: string) {
    let qrCode, artikelname, preis

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      var content = XLSX.utils.sheet_to_json(worksheet, { raw: true, header: 1 })

      let verkaeuferId: string = (""+content[1][2]).toUpperCase()      
      if (!this.verkaeuferSerivce.findByVerkaeuferId(verkaeuferId)) {
        this.config.presentToast("Es wurde kein Verkäufer mit der ID " + verkaeuferId + " gefunden.", "warning")
        return
      }

      for (let i = 4; i < 1500; i++) {
        if (!content || !content[i] || !content[i][0]) continue;

        qrCode = content[i][0]
        artikelname = content[i][1]
        preis = content[i][2]

        if (!artikelname && !preis) {
          continue
        }

        let artikel: Artikel = {
          name: artikelname ? artikelname : "",
          qrCode: qrCode ? qrCode : "",
          preis: preis ? +((""+preis).replace(",", ".")) : -1,
          verkaeuferId: verkaeuferId,
          eventId: eventId,
          status: ArtikelStatus.NEU,
          uploadTimestamp: new Date().getTime(),
          category: ""
        }

        this.artikels.push(this.getArtikelObject(artikel))
      }

      if (this.artikels.length == 0) {
        this.config.presentToast("Keine Artikel gefunden", "warning")
        return
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  getArtikelObject(artikel: Artikel): any {
    let success: boolean = true
    let error: string = undefined

    if (!this.config.isString(artikel.qrCode)) {
      success = false
      error = "QR Code fehlt"
    }

    console.log(artikel.qrCode, this.zahlenGeneratorService.isCodeValid(artikel.qrCode))
    if (!this.zahlenGeneratorService.isCodeValid(artikel.qrCode)) {
      success = false
      error = "QR Code ist nicht gültig"
    }

    if (!this.config.isString(artikel.name)) {
      success = false
      error = "Artikelname fehlt"
    }

    if (artikel.preis == -1) {
      success = false
      error = "Preis fehlt im Excel"
    }

    if (!this.config.isNumber(artikel.preis) || artikel.preis < 0) {
      success = false
      error = "Kein gültiger Preis"
    }

    if (this.artikelService.findByQrCode(artikel.qrCode)) {
      success = false
      error = "QR Code ist bereits zugewiesen"
    }

    return {
      obj: artikel,
      success: success,
      error: error
    }
  }

  allArtikelsValid(): boolean {
    for (let artikelWrapper of this.artikels) {
      if (!artikelWrapper.success) return false
    }
    return true
  }

  updateArtikelWrapper() {
    let copyArtikels: any[] = []

    // Update Artikels
    for (let artikelWrapper of this.artikels) {
      copyArtikels.push(this.getArtikelObject(artikelWrapper.obj))
    }
    this.artikels = copyArtikels
  }

  deleteFromArtikelWrapper(wrapper: any) {
    let index: number = this.artikels.indexOf(wrapper)
    if (index != -1) {
      this.artikels.splice(index, 1);
    }
  }

  takeOver(): boolean {
    this.updateArtikelWrapper()
    if (!this.allArtikelsValid()) {
      return false
    }

    this.artikels.forEach(artikel => {
      this.artikelService.add(artikel.obj)
    });
    this.artikels = []

    return true
  }

}
