import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Injectable } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user

  constructor(public auth: AngularFireAuth, private loadingController: LoadingController,
    private router: Router, private config: ConfigService) {
    this.user = auth.authState;
  }

  async login(password: string) {
    if (!this.config.isProdEnv() && password == "a") password = "miggelistderbeste"
    const loading = await this.loadingController.create({
      message: 'Anmelden...',
      duration: 10000
    });
    await loading.present();

    this
      .auth
      .signInWithEmailAndPassword("dummy@zweiteliebe.de", password)
      .then(value => {
        console.log('Erfolgreich angemeldet!', value);
        this.loadingController.dismiss()
      })
      .catch(err => {
        console.log('Something went wrong:', err.message);
        this.config.presentToast("Etwas ist schief gelaufen - falsches Passwort?", "warning")
        this.loadingController.dismiss()
      });
  }

}
