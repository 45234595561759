export const PATH_ARTIKELS = '/artikels'
export const PATH_VERKAEUFERS = '/verkaeufers'
export const PATH_EVENTS = '/events'
export const PATH_BELEGE = '/belege'

export const MARGE:number = 0.15

export const TEMP_BELEG_ID: string = 'temporary_'

export const EVENT_ID_LOCL: string = '6opWHcqL4ybfMMwUXm54'
export const EVENT_ID_TEST: string = '9HBH0lCueCklISCxRRZ2'
export const EVENT_ID_PROD: string = 'K461YaNgnb5tTeBCxpCW'
export const NEUENBURG_EVENT_ID_LOCL: string = 'WEHkfLqysVzeYavBraA8'
export const NEUENBURG_EVENT_ID_TEST: string = 'eX0OyPQOY6ICDtrjGMMP'
export const NEUENBURG_EVENT_ID_PROD: string = 'K461YaNgnb5tTeBCxpCW'


//export const BASE_URL_LOCL: string = "http://localhost:8080"                       
export const BASE_URL_LOCL: string = "https://zweiteliebe-grlm33ihxa-ew.a.run.app" //"https://zweiteliebe-test-bqxrow4dxa-uc.a.run.app"
export const BASE_URL_TEST: string = "https://zweiteliebe-grlm33ihxa-ew.a.run.app"
export const BASE_URL_PROD: string = "https://zweiteliebe-grlm33ihxa-ew.a.run.app"
//export const BASE_URL: string = "https://barela-ca314.ew.r.appspot.com"       // APP ENGINE

export const DEFAULT_ETIKETTEN_ANZAHL: number = 108 