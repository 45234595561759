import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArtikelStatus, Beleg, Kasse } from '../models/model';
import { DataService } from './data.service';
import { Html5Qrcode } from 'html5-qrcode';

@Injectable({
  providedIn: 'root'
})
export class BelegService {
  html5QrCodeScanner: Html5Qrcode
  scanStarted: boolean = false

  selectedKasse: Kasse = undefined
  belege: Beleg[]
  currentSubscription: Subscription
  currentBeleg: Beleg           // wenn im Bearbeitungsmodal

  constructor(private dataService: DataService) { }

  add(obj: Beleg): string {
    let id: string = this.dataService.createBeleg(obj)
    console.debug("Beleg mit ID " + id + " wurde hinzugefügt.")
    return id
  }

  update(obj: Beleg) {
    this.dataService.updateBeleg(obj)
    console.debug("Beleg aktualisiert", obj)
  }

  delete(id: string) {
    this.dataService.deleteBeleg(id)
    console.debug("Beleg gelöscht")
  }

  copy(obj: Beleg): Beleg {
    return Object.assign({}, obj)
  }

  calculateGesamtpreis(beleg: Beleg): number {
    let sum: number = 0
    for (let artikel of beleg.artikels) {
      sum += +artikel.preis
    }
    for (let einmalArtikel of beleg.einmalArtikels) {
      sum += +einmalArtikel.preis
    }
    return sum
  }

  findByBelegnummer(belegnummer: string): Beleg {
    return this.belege.find((obj) => {
      return obj.belegnummer == belegnummer;
    });
  }

  findById(belegId: string): Beleg {
    if (!belegId) return undefined

    return this.belege.find((obj) => {
      return obj.id == belegId;
    });
  }

  hasArtikels(beleg: Beleg): boolean {
    return beleg && beleg.artikels && beleg.artikels.length > 0
  }

  hasEinmalArtikels(beleg: Beleg): boolean {
    return beleg && beleg.einmalArtikels && beleg.einmalArtikels.length > 0
  }

  eventChanged(eventId: string) {
    if (this.currentSubscription) this.currentSubscription.unsubscribe();

    this.currentSubscription = this.dataService.belege(eventId).subscribe((belege: Beleg[]) => {
      console.log("Aktualisierte Beleg-Liste vom Server", belege)
      this.belege = belege

      if (!this.currentBeleg) return
      let tmpBeleg: Beleg = this.findById(this.currentBeleg.id) //TODO (fraglich? ob noch besteht) er sucht wenn jemadn anders saved hat, nach belegnummer: aber den gibt es gar nicht mehr, weil ein neuer beleg mit adnerer belegnummer beim anderen entstanden ist
      if (tmpBeleg) { 
        this.currentBeleg = tmpBeleg
      }
    })
  }

  stopScan() {
    this.scanStarted = false

    if (!this.html5QrCodeScanner || !this.html5QrCodeScanner.isScanning) {
      return
    }

    this.html5QrCodeScanner.stop().then((ignore) => {
      // QR Code scanning is stopped.
    }).catch((err) => {
      // Stop failed, handle it.
    });
  }
}
