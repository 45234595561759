import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ArtikelScannerGuard } from 'src/app/guards/artikel-scanner.guard';
import { KassenScannerGuard } from 'src/app/guards/kassen-scanner.guard';
import { AuswertungsGuard } from 'src/app/guards/auswertung.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'kasse',
    canDeactivate: [KassenScannerGuard],
    loadChildren: () => import('./pages/kasse/kasse.module').then(m => m.KassePageModule)
  },
  {
    path: 'verkaeufer',
    loadChildren: () => import('./pages/verkaeufer/verkaeufer.module').then(m => m.VerkaeuferPageModule)
  },
  {
    path: 'verkaeufer/:verkaeuferId',
    loadChildren: () => import('./pages/verkaeufer/verkaeufer.module').then(m => m.VerkaeuferPageModule)
  },
  {
    path: 'etiketten',
    loadChildren: () => import('./pages/etiketten/etiketten.module').then(m => m.EtikettenPageModule)
  },
  {
    path: 'import',
    loadChildren: () => import('./pages/import/import.module').then(m => m.ImportPageModule)
  },
  {
    path: 'artikel',
    canDeactivate: [ArtikelScannerGuard],
    loadChildren: () => import('./pages/artikel/artikel.module').then(m => m.ArtikelPageModule)
  },
  {
    path: 'artikel/:id',
    loadChildren: () => import('./pages/artikel/artikel.module').then(m => m.ArtikelPageModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/event/event.module').then(m => m.EventPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'auswertungen',
    canActivate: [AuswertungsGuard],
    loadChildren: () => import('./pages/auswertungen/auswertungen.module').then(m => m.AuswertungenPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
