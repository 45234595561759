import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Artikel, ArtikelStatus, SortDirection, EinmalArtikel } from '../models/model';
import { DataService } from './data.service';
import { ConfigService } from 'src/app/services/config.service';
import { Html5Qrcode } from 'html5-qrcode';
import { TransferArtikelService } from './transfer-artikel.service';

@Injectable({
  providedIn: 'root'
})
export class ArtikelService {
  html5QrCodeScanner: Html5Qrcode
  scanStarted: boolean = false
  
  artikels: Artikel[]
  searchstring: string = ""
  currentSubscription: Subscription
  currentArtikel: Artikel           // wenn im Bearbeitungsmodal

  constructor(private dataService: DataService, private config: ConfigService, private transferArtikelService: TransferArtikelService) { }

  get artikelCodes(): string[] {
    return this.artikels.map(a => a.qrCode);
  }

  add(obj: Artikel): string {
    let id: string = this.dataService.createArtikel(obj)
    console.debug("Artikel mit ID " + id + " wurde hinzugefügt.")
    return id
  }

  update(obj: Artikel) {
    this.dataService.updateArtikel(obj)
    console.debug("Artikel aktualisiert", obj)
  }

  delete(id: string) {
    this.dataService.deleteArtikel(id)
    console.debug("Artikel gelöscht")
  }

  copy(obj: Artikel): Artikel {
    return Object.assign({}, obj)
  }

  copyAll(list: Artikel[]): Artikel[] {
    let copiedArtikels: Artikel[] = []
    for (let artikel of list) {
      copiedArtikels.push(this.copy(artikel))
    }
    return copiedArtikels
  }

  findByQrCode(qrCode: string): Artikel {
    return this.artikels.find((obj) => {
      return obj.qrCode.toUpperCase() == qrCode.toUpperCase();
    });
  }

  findByVerkaeuferId(verkaeuferId: string): Artikel[] {
    return this.artikels.filter((obj) => {
      return obj.verkaeuferId == verkaeuferId;
    });
  }

  findByStatus(status: ArtikelStatus, verkaeuferId: string = undefined): Artikel[] {
    return this.artikels.filter((obj) => {
      obj.status == status && (!verkaeuferId || obj.verkaeuferId == verkaeuferId);
    });
  }

  sortForAuswertung(artikels: Artikel[], verkaeuferId: string = undefined): Artikel[] {
    let compareResult: number

    return artikels.filter((obj) =>
      (!verkaeuferId || obj.verkaeuferId == verkaeuferId)
    ).sort((a, b) => {
      compareResult = this.config.compareStrings(a.verkaeuferId, b.verkaeuferId, SortDirection.DESC)
      if (compareResult == 0) {
        compareResult = this.config.compareStrings(a.status, b.status, SortDirection.ASC)
      }
      if (compareResult == 0) {
        compareResult = this.config.compareStrings(a.qrCode, b.qrCode, SortDirection.DESC)
      }
      return compareResult;
    });
  }

  isArtikelInArtikelliste(artikel: Artikel, artikelListe: Artikel[]): boolean {
    for (let a of artikelListe) {
      if (a.qrCode == artikel.qrCode) {
        return true
      }
    }
    return false
  }

  eventChanged(eventId: string) {
    if (this.currentSubscription) this.currentSubscription.unsubscribe();

    this.currentSubscription = this.dataService.artikels(eventId).subscribe((artikels: Artikel[]) => {
      console.debug("Aktualisierte Artikel-Liste vom Server", artikels)
      this.artikels = artikels
      this.transferArtikelService.exportJsons(artikels, null)
    })
  }

  reset(_artikel: Artikel) {
    let artikel: Artikel = this.findByQrCode(_artikel.qrCode)
    if (artikel && (artikel.status != ArtikelStatus.NEU && artikel.status != ArtikelStatus.REGISTRIERT)) {
      artikel.status = ArtikelStatus.REGISTRIERT
      this.update(artikel)
    }
  }

  equalEinmalArtikel(a: EinmalArtikel, b: EinmalArtikel): boolean {
    return (a.name == b.name && a.preis == b.preis)
  }

  sumUp(verkaeuferId?: string, artikelStatus?: ArtikelStatus): number {
    let sum: number = 0
    for (let artikel of this.artikels) {
      if (!verkaeuferId || verkaeuferId == artikel.verkaeuferId) {
        if (!artikelStatus || artikelStatus == artikel.status) {
          sum += +artikel.preis
        }
      }
    }

    return sum
  }

  
  countUp(verkaeuferId?: string, artikelStatus?: ArtikelStatus): number {
    let count: number = 0
    for (let artikel of this.artikels) {
      if (!verkaeuferId || verkaeuferId == artikel.verkaeuferId) {
        if (!artikelStatus || artikelStatus == artikel.status) {
          count++
        }
      }
    }

    return count
  }

  indexEinmalArtikel(obj: EinmalArtikel, array: EinmalArtikel[]): number {
    for (let i = 0; i < array.length; i++) {
      if (this.equalEinmalArtikel(obj, array[i])) return i
    }
    return -1
  }

  stopScan(){
    this.scanStarted = false

    if (!this.html5QrCodeScanner || !this.html5QrCodeScanner.isScanning) {
      this.searchstring = ""
      return
    }

    this.html5QrCodeScanner.stop().then((ignore) => {
      // QR Code scanning is stopped.
    }).catch((err) => {
      // Stop failed, handle it.
    });
  }
}
