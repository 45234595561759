import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { ChartFilter } from 'src/app/models/model';
import { Chart, registerables } from 'chart.js';
import { AuswertungenService } from 'src/app/services/auswertungen.service';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, AfterContentInit {

  constructor(private auswertungenService: AuswertungenService, private verkaeuferService: VerkaeuferService) {
  }

  ngOnInit() {
    Chart.register(...registerables);
  }

  ngAfterContentInit(): void {
    console.log("ChartComponent, ngAfterContentInit")
    this.init()
  }

  init() {
    this.initTotalSoldCountPerTime()
    this.initTotalCountPerCustomer()
  } 

  initTotalCountPerCustomer() {

    let counts = []
    let labels = []

    for (let verkaeuferStats of this.auswertungenService.totalCount) {
      labels.push(this.verkaeuferService.findByVerkaeuferId(verkaeuferStats.verkaeuferId).name + " (" + verkaeuferStats.verkaeuferId + ")")
      counts.push(verkaeuferStats.totalCount)
    }

    let _cavasTotalCountPerCustomer: any = document.getElementById("canvasTotalCountPerCustomer")
    const _chartTotalCountPerCustomer = new Chart(_cavasTotalCountPerCustomer, {
      type: 'polarArea',
      data: {
        labels: labels,
        datasets: [{
          label: 'Anzahl Artikel pro Kunde',
          data: counts,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(75, 192, 192)',
            'rgb(255, 205, 86)',
            'rgb(201, 203, 207)',
            'rgb(54, 162, 235)'
          ]
        }]
      }
    })
    this.auswertungenService.chartTotalCountPerCustomer = _chartTotalCountPerCustomer
  }

  initTotalSoldCountPerTime() {
    let _canvasTotalSoldCountPerTime: any = document.getElementById("canvasTotalSoldCountPerTime")
    const _chartTotalSoldCountPerTime = new Chart(_canvasTotalSoldCountPerTime, {
      type: 'bar',
      data: {
        labels: this.auswertungenService.chartLabels60Minutes,
        datasets: [{
          label: 'Anzahl verkaufter Artikel',
          data: this.auswertungenService.chartTotalSoldCountPer60Minutes(),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235,, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
    this.auswertungenService.chartTotalSoldCountPerTime = _chartTotalSoldCountPerTime
  }




}
