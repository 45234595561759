import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { SortDirection } from 'src/app/models/model';
import { EVENT_ID_PROD, EVENT_ID_TEST, EVENT_ID_LOCL } from 'src/app/models/constants';
import { BASE_URL_LOCL, BASE_URL_PROD, BASE_URL_TEST } from '../models/constants';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  loggedIn: boolean = false
  demo: boolean = false

  constructor(public modalController: ModalController, public toastController: ToastController, public alertController: AlertController) { }

  isNumber(n): boolean {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
  }

  isString(s): boolean {
    try {
      if (!s) return false;
      s = "" + s
      return s && s.trim().length > 0
    } catch (e) {
      console.error("error trimming", s)
      return false
    }
  }

  isLoclEnv(): boolean {
    //if(2>1) return false
    return window.location.href.indexOf('zweiteliebe-locl.web.app') != -1 || window.location.href.indexOf('localhost') != -1
  }

  isTestEnv(): boolean {
    //if(2>1) return false
    return window.location.href.indexOf('zweiteliebe-test.web.app') != -1
  }

  isProdEnv(): boolean {
    //if(2>1) return true
    return !this.isTestEnv() && !this.isLoclEnv()
  }

  get currentEventId(): string {
    if (this.isLoclEnv()) {
      return EVENT_ID_LOCL
    } else if (this.isTestEnv()) {
      return EVENT_ID_TEST
    } else {
      return EVENT_ID_PROD
    }
  }

  get dbVersion(): string {
    if (this.isLoclEnv()) {
      return 'locl'
    } else if (this.isTestEnv()) {
      return 'test'
    } else {
      return 'prod'
    }
  }

  get baseUrl(): string {
    // if(2>1) return BASE_URL_LOCL
    if (this.isLoclEnv()) {
      return BASE_URL_LOCL
    } else if (this.isTestEnv()) {
      return BASE_URL_TEST
    } else {
      return BASE_URL_PROD
    }
  }


  async presentModal(component) {
    const modal = await this.modalController.create(component);
    await modal.present();
    return await modal.onWillDismiss();
  }

  async presentAlertConfirm(success, title = "Bestätigung", text = "", cancelText = "Abbrechen", agreeText = "Ja") {
    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: cancelText, role: 'cancel', cssClass: 'secondary', handler: (blah) => { console.debug("Not Confirmed") }
      }, {
        text: agreeText,
        handler: () => {
          success()
        }
      }
      ]
    });

    await alert.present();
  }

  async presentAlertPrompt(success, title = "Bestätigung", subheader = "", value = "", placeholder = "", cancelText = "Abbrechen", agreeText = "Speichern") {
    const alert = await this.alertController.create({
      header: title,
      subHeader: subheader,
      inputs: [{ name: 'input', type: 'text', id: 'input', value: value, placeholder: placeholder }],
      buttons: [{
        text: cancelText, role: 'cancel', cssClass: 'secondary', handler: () => { console.log('Confirm Cancel'); }
      }, {
        text: agreeText,
        handler: (data) => {
          success(data)
        }
      }
      ]
    });
    return alert.present().then(() => {
      const firstInput: any = document.querySelector('ion-alert input');
      if (firstInput) firstInput.focus();
      return;
    });
  }

  createAlertCheckInput(name, label, value, currentVal, checked = undefined, enabled = true, type = 'radio') {
    return {
      name: name,
      type: type,
      label: label,
      value: value,
      disabled: !enabled,
      checked: checked !== undefined ? checked : value === currentVal
    }
  }

  async presentAlertCheck(success, inputData: any[], title = "Bestätigung", text = "", cancelText = "Abbrechen", agreeText = "OK") {
    const alert = await this.alertController.create({
      header: title,
      subHeader: text,
      inputs: inputData,
      buttons: [
        { text: cancelText, role: 'cancel', cssClass: 'secondary' }, {
          text: agreeText,
          handler: (data) => {
            success(data)
          }
        }
      ]
    });

    await alert.present();
  }

  async presentToast(text: string, color = "danger", duration = 2000) {
    const toast = await this.toastController.create({
      message: text,
      duration: duration,
      position: "top",
      color: color
    });
    toast.present();
  }

  deleteFromArray(array: any[], key): any[] {
    let index = array.indexOf(key);

    if (index > -1) {
      array.splice(index, 1);
    }
    return array
  }

  addToArray(array: any[], obj: any): any[] {
    let index = array.indexOf(obj);

    if (index == -1) {
      array.push(obj)
    }
    return array
  }

  isInArray(array: any[], key: string): boolean {
    if (!array) return false
    for (let obj of array) {
      if (obj == key) {
        return true;
      }
    }
    return false;
  }

  toCurrencyString(betrag: number, dec = 2): string {
    if (!this.isNumber(betrag)) return "0.00";
    if (betrag == 0) return "0.00"
    return (+betrag).toFixed(dec);
  }

  compareStrings(a: string, b: string, sortDirection: SortDirection = SortDirection.ASC): number {
    if (!a) a = ""
    if (!b) b = ""

    return sortDirection == SortDirection.DESC ? a.localeCompare(b, 'de', { numeric: true }) : b.localeCompare(a, 'de', { numeric: true })
  }
}
