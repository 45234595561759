import { Pipe, PipeTransform } from '@angular/core';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';
import { Artikel, SortFields, SortDirection } from 'src/app/models/model';
import { ConfigService } from 'src/app/services/config.service';

@Pipe({
  name: 'artikelSort'
})
export class ArtikelSortPipe implements PipeTransform {

  constructor(private verkaeuferService: VerkaeuferService, private config: ConfigService) {

  }

  transform(artikels: Artikel[], sortBy: SortFields, sortDirection: SortDirection): Artikel[] {
    if (!artikels) return []
    return artikels.sort((a: Artikel, b: Artikel) => {
      switch (sortBy) {
        case SortFields.CODE: return this.config.compareStrings(a.qrCode, b.qrCode, sortDirection)
        case SortFields.NAME: return this.config.compareStrings(a.name, b.name, sortDirection)
        case SortFields.VERKAEUFER:
          let verkaeufer_a = this.verkaeuferService.findByVerkaeuferId(a.verkaeuferId)
          let verkaeufername_a = verkaeufer_a ? verkaeufer_a.name : ""
          let verkaeufer_b = this.verkaeuferService.findByVerkaeuferId(b.verkaeuferId)
          let verkaeufername_b = verkaeufer_b ? verkaeufer_b.name : ""
          return this.config.compareStrings(verkaeufername_a, verkaeufername_b, sortDirection)

        case SortFields.PREIS: return sortDirection == SortDirection.DESC ? a.preis - b.preis : b.preis - a.preis
        case SortFields.STATUS: return this.config.compareStrings(a.status, b.status, sortDirection)
      }
      return 0
    });
  }

}