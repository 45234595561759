import { Component, OnInit, Input } from '@angular/core';
import { Verkaeufer } from 'src/app/models/model';
import { ModalController } from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
import { VerkaeuferService } from 'src/app/services/verkaeufer.service';
import { ConfigService } from 'src/app/services/config.service';
import { ZahlenGeneratorService } from 'src/app/services/zahlen-generator.service';

@Component({
  selector: 'app-edit-verkaeufer',
  templateUrl: './edit-verkaeufer.component.html',
  styleUrls: ['./edit-verkaeufer.component.scss'],
})
export class EditVerkaeuferComponent implements OnInit {

  isNew: boolean = false
  @Input() verkaeufer: Verkaeufer

  constructor(public eventService: EventService, public verkaeuferService: VerkaeuferService, public modalController: ModalController, public config: ConfigService,
    public zahlenGeneratorService: ZahlenGeneratorService) { }

  ngOnInit() {
    this.init()
  }

  init(){
    if(!this.eventService.currentEvent) {
      return
    }

    if (!this.verkaeufer) {
      this.isNew = true
      
      this.verkaeufer = {
        name: "",
        verkaeuferId: this.zahlenGeneratorService.generateCodes(1, this.eventService.currentEvent.lastVerkaeuferId)[0],
        eventId: this.config.currentEventId,
        email: "",
        phone: "",
        street: "",
        plz: "",
        city: "",
        country: "Deutschland",
        note: ""
      }
      this.eventService.currentEvent.lastVerkaeuferId = this.verkaeufer.verkaeuferId
      this.eventService.update(this.eventService.currentEvent)
    }
  } 

  getErrorMessage(): string {
    let errorMessage = ''

    if (!this.config.isString(this.verkaeufer.name)) {
      errorMessage += 'Bitte benenne den Verkäufer'
    }

    if(this.verkaeuferService.findByName(this.verkaeufer.name.toUpperCase()) != undefined && this.isNew) {
      errorMessage += 'Dieser Name wurde bereits einem Verkäufer zugewiesen | '      
    }

    return errorMessage
  }

  submit() {
    let validatioErrors = this.getErrorMessage()
    if (validatioErrors) {
      this.config.presentToast("Fehler: " + validatioErrors)
      return false;
    }

    this.modalController.dismiss({
      'verkaeufer': this.verkaeufer
    });
  }

}
