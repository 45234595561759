import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class EtikettenService {

  constructor() { }

  // window.open(`${fcmUrl}/pdf-extend/${this.db.currentRestaurantKey}/${this.db.currentRestaurant.name}/${d1.getTime()}/${d2.getTime()}`)     

}
