import { Injectable } from '@angular/core';
const chars: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

@Injectable({
  providedIn: 'root'
})
export class ZahlenGeneratorService {

  constructor() { }

  generateCodes(numberOfCodes: number, lastCode: string = "1111", usedCodes: string[] = []): string[] {
    let codes: string[] = []

    let i: number = 0;
    let eins: number = this.findInArray(chars, lastCode.charAt(0));
    let zwei: number = this.findInArray(chars, lastCode.charAt(1));
    let drei: number = this.findInArray(chars, lastCode.charAt(2));
    let vier: number = this.findInArray(chars, lastCode.charAt(3)) + 1

    if (vier >= chars.length) {
      vier = 0;
      drei += 1;
    }
    if (drei >= chars.length) {
      drei = 0;
      zwei += 1;
    }
    if (zwei >= chars.length) {
      zwei = 0;
      eins += 1;
    }
    if (eins >= chars.length) {
      return;
    }
    for (var _eins = eins; _eins < chars.length; _eins++) {
      for (var _zwei = zwei; _zwei < chars.length; _zwei++) {
        for (var _drei = drei; _drei < chars.length; _drei++) {
          for (var _vier = vier; _vier < chars.length; _vier++) {
            let value: string = "" + chars[_eins] + chars[_zwei] + chars[_drei] + chars[_vier] + this.calculateCheckSum("" + chars[_eins] + chars[_zwei] + chars[_drei] + chars[_vier]);
            if(!usedCodes.includes(value)) {
              codes.push(value);
              usedCodes.push(value)
            }

            if (codes.length >= numberOfCodes) {
              return codes
            }

            i++;
          }
          vier = 0;
        }
        drei = 0;
      }
      zwei = 0;
    }
    return codes
  }

  isCodeValid(code: string): boolean {
    let calculatedChecksum: string = this.calculateCheckSum(code.substring(0, code.length - 1))
    let checksum: string = code.charAt(code.length - 1)

    return (calculatedChecksum.toUpperCase() == checksum.toUpperCase())
  }

  private findInArray(array: string[], char: string): number {
    for (let i = 0; i < array.length; i++) {
      if (array[i] == char) {
        return i;
      }
    }
    return 0;
  }

  private calculateCheckSum(value: string): string {
    let sum: number = 0;
    for (var i = 0; i < value.length; i++) {
      if (i == 0) sum = sum + (value.charAt(i).charCodeAt(0) * 17);
      if (i == 1) sum += (value.charAt(i).charCodeAt(0) * 12);
      if (i == 2) sum += (value.charAt(i).charCodeAt(0) * 93);
      if (i == 3) sum += (value.charAt(i).charCodeAt(0) * 29);
      else sum += (value.charAt(i).charCodeAt(0) * 7);
    }

    return chars[sum % 33];
  }

}
