import { Observable } from "rxjs"

export class Result {
    /*
        Return Codes:
        200 = OK
        404 = Not Found
    */
    constructor(public success: boolean, public returnCode: number) { }

    error: string
    value: any

    static Ok(): Result {
        return new Result(true, 200)
    }

    static Value(value: any): Result {
        let r: Result = new Result(true, 200)
        r.value = value
        return r
    }

    static Error(code: number, errorMessage: string = "-"): Result {
        let r: Result = new Result(false, code)
        r.error = errorMessage
        return r
    }
}

export interface VerkausEvent {
    id?: string
    mId?: string,
    name: string,
    lastUsedEtikettenCode: string,
    lastUsedBelegCode: string,
    lastVerkaeuferId: string,
    timestamp_von: number,
    timestamp_bis: number,
    city: string,
    locationName: string,
    locationAdresse: string,
    organisatorId: string,
    kassen: Kasse[]
    einmalArtikels: EinmalArtikel[]
}

export interface Verkaeufer {
    id?: string
    eventId: string
    verkaeuferId: string
    name: string,
    email?: string,
    phone?: string,
    street?: string,
    plz?: string,
    city?: string,
    country?: string,
    note?: string
}

export interface Artikel {
    id?: string
    qrCode: string
    name: string,
    preis: number,
    verkaeuferId: string,
    eventId: string,
    status: ArtikelStatus,
    uploadTimestamp: number,
    category: string
}

export interface EinmalArtikel {
    name: string,
    preis: number
}

export interface Kasse {
    name: string
    kassenId: string
    currentBelegId: string
    suffix: string  //necessary so that every beleg for a kasse is individual since it is only saved/incremented at creation
}

export interface Beleg {
    id?: string
    timestamp: number
    belegnummer: string
    gesamtpreis: number
    zahlungsart: Zahlungsart
    artikels: Artikel[]
    einmalArtikels: EinmalArtikel[]
    eventId: string
}

export enum ChartFilter {
    ALLE = "Alle"
}

export enum ArtikelStatus {
    NEU = "Neu",
    VERKAUFT = "Verkauft",
    NICHT_VERKAUFT = "Nicht Verkauft",
    REGISTRIERT = "Registriert"
}

export enum Zahlungsart {
    BAR = "BAR",
    KARTE = "KARTE"
}

export enum SortFields {
    CODE = "CODE",
    NAME = "NAME",
    PREIS = "PREIS",
    STATUS = "STATUS",
    VERKAEUFER = "VERKAEUFER",
    EMAIL = "EMAIL"
}

export enum SortDirection {
    ASC, DESC
}

export interface Database {
    setReferences(eventId: string)
    event(eventId: string): Observable<VerkausEvent>
    updateEvent(obj: VerkausEvent)
    createEvent(event: VerkausEvent): string
    artikels(eventId: string): Observable<Artikel[]>
    createArtikel(obj: Artikel): string
    updateArtikel(obj: Artikel)
    deleteArtikel(id: string)
    verkaeufers(eventId: string): Observable<Verkaeufer[]>
    createVerkaeufer(obj: Verkaeufer): string
    updateVerkaeufer(obj: Verkaeufer)
    deleteVerkaeufer(id: string)
    belege(eventId: string): Observable<Beleg[]>
    createBeleg(obj: Beleg): string
    updateBeleg(obj: Beleg)
    deleteBeleg(id: string)
}
