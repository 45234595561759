import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from "@angular/core";
import { KassePage } from 'src/app/pages/kasse/kasse.page';
import { BelegService } from 'src/app/services/beleg.service';

@Injectable()
export class KassenScannerGuard implements CanDeactivate<KassePage> {

    constructor(private belegService: BelegService) { }

    canDeactivate(component: KassePage, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        this.belegService.stopScan()

        return true
    }

}
